import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import SingleItemSelectorModal from './singleitemselectormodal';

export default Vue.component('singleitemselector', {
    template:`
    <div>
        <div class="input-group input-group-sm">            
            <input type="hidden" :name="bindedPropertyName" v-bind:value="currentSelection" />
            <span class="form-control">{{ currentSelectionLabel }}</span>
            <div class="input-group-append">
                <button class="btn btn-secondary" type="button" v-on:click="removeSelection">Clear</button>
                <button class="btn btn-primary" type="button" v-on:click="showModal = true">Select</button>
            </div>
        </div>
        <modal v-if="showModal" v-on:close="showModal = false" key="'selector-modal' + bindedPropertyName" 
            v-on:confirm="updateSelection"
            v-bind="{ baseUrl, currentSelection, searchParameters, filters }" 
            v-bind:paging="pagination" >
        </modal>
    </div>
    `,
    data: function(){
        return {
            showModal: false,
            axe: axios.create({
                baseURL: this.$props.baseUrl,
                timeout: 1500,
                paramsSerializer: function(params) {
                    return qs.stringify(params, { indices: false, allowDots: true })
                }
            }),
            pagination: this.$props.paging,
            selectedItem: this.$props.currentItem
        }
    },
    computed: {
        currentSelection: function(){
            return this.selectedItem !== null ? this.selectedItem.value : null;
        },
        currentSelectionLabel: function(){
            return this.selectedItem !== null ? this.selectedItem.label : '';
        }        
    },
    components: {
        'modal': SingleItemSelectorModal
    },
    props: {
        baseUrl: String,
        currentItem: Object,
        searchParameters: Object,
        paging: Object,
        bindedPropertyName: String,
        filters: Object
    },
    methods:{
        removeSelection: function() {
            this.selectedItem = null;
        },
        updateSelection: function(itemSelection){
            this.axe.get('/getselecteditem', { params: { selectedItem: itemSelection } })
                .then((response) => {
                    this.selectedItem = response.data;
                    this.showModal = false;
                })
                .catch((error) => { console.log('Error updating selection : ' + error); });
        }
    }
});