import Vue from 'vue/dist/vue.esm';
import axios from 'axios';

export default Vue.component('cookiebar', {
    template:`
    <div v-if="isAgreed === false" class="cookie-consent">
        <p>{{ text }}</p>
        <div class="button-group">
            <button class="button" @click="accept">{{ resources.acceptText }}</button>    
            <button class="button" @click="decline">{{ resources.declineText }}</button>
        </div>
    </div>
    `,
    data: function(){
        return {
            axe: axios.create({ baseURL: '/trackingconsent' }),
            isAgreed: false
        }
    },
    props: {
        text: String,
        resources: Object
    },
    methods:{
        accept: function(){
            this.axe.get('/accept')
                .then((response) => { if(response.data.success){ this.isAgreed = true; } })
                .catch((error) => { console.log('error accepting cookies : ' + error); });
        },
        decline: function(){
            this.axe.get('/reject')
                .then((response) => { if(response.data.success){ this.isAgreed = true; } })
                .catch((error) => { console.log('error revoking cookies : ' + error); });
        }
    }
});

    