import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import qs from 'qs';
import SiteTreeItem from './sitetreeitem';

export default Vue.component('sitetree', {
    template:`
    <ul class="site-tree">
        <tree-item v-if="treeData !== null" class="item" :item="treeData" :axe="axe" @select-item="select"></tree-item>
    </ul>
    `,
    components: {
        'tree-item' : SiteTreeItem
    },
    data: function(){
        return {
            axe: axios.create({
                baseURL: this.$props.baseUrl,
                paramsSerializer: function(params) {
                    return qs.stringify(params, { indices: false, allowDots: true })
                }
            }),
            selectedItem: null,
            treeData: null,
        }
    },
    props: {
        baseUrl: String
    },
    methods: {
        select: function(selectedNode){            
            this.$emit('select-item', selectedNode);
        }
    },
    created: function(){
        this.axe.get('/GetSiteTreeRootNode')
            .then((response) => { this.treeData = response.data; })
            .catch((error) => { console.log('error retrieving site tree data : ' + error); });
    }
});