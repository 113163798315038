import Vue from 'vue/dist/vue.esm';

export default Vue.component('ckeditormodal', {
    props: {
        resources: {
            type: Object,
            required: false
        },
        close: Function
    },
    methods:{
        /** Call ckeditor close modal handler */
        closeModal: function(){
            this.close();
        }
    }
});