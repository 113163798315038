import Vue from 'vue/dist/vue.esm';
import _ from 'lodash';
import axios from 'axios';
import qs from 'qs';
import debounce from 'debounce';
import Paginate from '../../../paginate'

export default Vue.component('singleitemselectorgrid', {
    template:`
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group row input-group-sm">
                    <label class="col-sm-2 col-form-label">Name</label>
                    <div class="col-sm-4">
                        <input type="text" class="form-control" v-model="parameters.keyword" v-on:keyup="updateKeyword()">
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <table class="table table-striped table-sm singleselection">
                    <thead>
                        <tr>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items" v-if="items.length" v-bind:class="{ 'selected': item.value === itemSelection }">
                            <td v-on:click="updateItemSelection(item)">
                                <span>{{ item.label }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="pagination.pageCount > 1">
                    <paginate v-model="pagination.pageNumber"
                            :page-count="pagination.pageCount"
                            :page-range="3"
                            :click-handler="getSearchResultsByPage"
                            :active-Alt="'Huidige pagina'"
                            :prev-text="'<'"
                            :prev-alt="'Terug'"
                            :next-text="'>'"
                            :next-alt="'Vooruit'"
                            :container-class="'pagination text-center'"
                            :page-class="'page-item'">
                    </paginate>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="btn-group btn-group-sm float-right" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary" @click="closeGrid">Cancel</button>
                    <button type="button" class="btn btn-primary" @click="confirmSelection">Apply</button>
                </div>
            </div>
        </div>
    </div>
    `,
    components: {
        'paginate': Paginate
    },
    props: {
        baseUrl: String,
        currentSelection: String,
        paging: Object,
        modalId: String,
        searchParameters: Object,
        onItemSelected: Function,
        close: Function,
        filters: Object
    },
    data: function(){
        return {
            axe: axios.create({
                baseURL: this.$props.baseUrl,
                paramsSerializer: function(params) {
                    return qs.stringify(params, { indices: false, allowDots: true })
                }
            }),
            items: [],
            item: null,
            itemSelection: this.$props.currentSelection,
            parameters: this.$props.searchParameters,
            pagination: this.$props.paging,
            parametersFilters: { }
        }
    },
    methods:{
        getSearchResultsByPage: function(pageNumber){
            this.pagination.pageNumber = pageNumber;
            this.parameters.pageNumber = pageNumber;

            this.getSearchResults(this.parameters);
        },
        getSearchResults: function(parameters){
            this.axe.get('/getsearchresults', { params: parameters })
                .then((response) => { 
                    this.setSearchResults(response.data.resultItems);
                    this.pagination.pageCount = response.data.pageCount;
                    this.pagination.resultCount = response.data.resultCount;
                })
                .catch((error) => { console.log('Error retrieving results : ' + error); });
        },
        setSearchResults: function(resultItems){
            this.items = resultItems;
            this.items.forEach(element => {
                element.checked = _.includes(this.itemSelection, element.value);
            });
        },
        updateKeyword: function(){
            // move to debounce
            clearTimeout(this.reloadtimer);
            this.reloadtimer = setTimeout(() => { this.getSearchResultsByPage(1); }, 500);
        },
        /** Update grid item selection list */
        updateItemSelection: function(item){            
            if(this.itemSelection === item.value){
                this.itemSelection = null;
                this.item = null;
            } else {
                this.itemSelection = item.value;
                this.item = item;
            }
        },
        confirmSelection: function(){
            if(this.$props.onItemSelected !== undefined){
                this.$props.onItemSelected(this.item);
            } else {
                this.$emit('confirm', this.itemSelection);
            }
        },
        closeGrid: function(){
            if(this.$props.close !== undefined) {
                this.$props.close();
            } else {
                this.$emit('close');
            }
        }
    },
    created: function() {
        this.getSearchResultsByPage(1);
    }
});