import Vue from 'vue/dist/vue.esm';
import Paginate from '../../../paginate'
import Select from 'vue-select';
import MultiSelectorModal from '../../core/itemselector/multipleitemselectormodal';
import debounce from 'debounce';

export default Vue.component('pagesselectormodal', {
    extends: MultiSelectorModal,
    template:`
    <transition name="modal">
        <div id="xpto" class="modal-mask">
            <div class="modal-wrapper">
                <div class="flv modal-container">
                    <div class="modal-header">
                        <div class="modal-header-controls">
                            <a class="bo-icon" @click="$emit('close')">
                                <i aria-hidden="true" title="Close" class="icon-modal-close"></i>
                            </a>
                        </div>
                        <div class="modal-header-title">Select items</div>
                    </div>
                    <div class="modal-body">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group row input-group-sm">
                                        <label class="col-sm-2 col-form-label">Name</label>
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control" v-model="parameters.keyword" v-on:keyup="updateKeyword()">
                                        </div>
                                    </div>
                                    <div class="form-group row input-group-sm">
                                        <label class="col-sm-2 col-form-label">Page Type</label>
                                        <div class="col-sm-4">
                                            <v-select multiple v-model="parametersFilters.classNames" :options="filters.classNames" @input="updateClassNamesFilter"></v-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <table class="table table-striped table-sm singleselection">
                                        <thead>
                                            <tr>
                                                <th><input type="checkbox" /></th>
                                                <th>Name</th>
                                                <th>Page Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in items" v-if="items.length">
                                                <td>
                                                    <input type="checkbox" v-bind:id="'selection_' + item.itemId" v-model="item.checked" v-on:change="updateItemSelection(item)" />
                                                    <label v-bind:for="'selection_' + item.itemId"><i></i></label>
                                                </td>
                                                <td>
                                                    <div class="selectarea" v-on:click="updateItemSelection(item)">
                                                        <span class="bo-icon" v-on:click="previewPage($event, item)">
                                                            <i aria-hidden="true" class="icon-eye"></i>
                                                        </span>                                                        
                                                        <span>{{ item.label }}</span>
                                                    </div>
                                                </td>
                                                <td><span>{{ item.className }}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="pagination.pageCount > 1">
                                        <paginate v-model="pagination.pageNumber"
                                                :page-count="pagination.pageCount"
                                                :page-range="3"
                                                :click-handler="getSearchResultsByPage"
                                                :active-Alt="'Huidige pagina'"
                                                :prev-text="'<'"
                                                :prev-alt="'Terug'"
                                                :next-text="'>'"
                                                :next-alt="'Vooruit'"
                                                :container-class="'pagination text-center'"
                                                :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="btn-group btn-group-sm float-right" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
                                        <button type="button" class="btn btn-primary" @click="confirmSelection">Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>`,
    components: {
        'paginate': Paginate,
        'v-select': Select
    },
    methods: {
        previewPage: function(event, item){
            window.open(item.pageUrl);
        },
        updateClassNamesFilter: function(){
            this.parameters.classNames = this.parametersFilters.classNames.map(o => o.value);
            this.getSearchResultsByPage(1);
        }
    }
});