import Vue from 'vue/dist/vue.esm';
import Foundation from 'foundation-sites';
import { OffCanvas, Equalizer, Drilldown, AccordionMenu } from 'foundation-sites';
import objectFitImages from 'object-fit-images';

const DEBUG = true;
let WindowInitialized = false;

export function initializeWindow(){
    if(WindowInitialized === false){
        WindowInitialized = true;
        initializeFoundation();
        initializeModules();
        initializeVue();        
        initializeObjectFitImages();
    }
}

export function initializeFoundation(){    
    /** Extend jquery with foundation */
    Foundation.addToJquery($);

    /** Add plugins to foundation */
    Foundation.plugin(OffCanvas, 'OffCanvas');
    Foundation.plugin(Equalizer, 'Equalizer');
    Foundation.plugin(Drilldown, 'Drilldown');
    Foundation.plugin(AccordionMenu, 'AccordionMenu');

    /** Initialize foundation */
    $(document).foundation();

    /** Initialize accordion menu for mobile  **/
    initializeMobileMenu();

    /** Initialize accordion menu accessibility fix **/
    initializeAccordionMenuAccessibilityFix();
}

export function initializeFoundationElement(element){
    $(element).foundation();
}

/** Initializes vue components */
export function initializeVue(){
    const vueElements = document.querySelectorAll('[data-vue]');
    for (var i = 0; i < vueElements.length; i++) {
        initializeVueElement(vueElements[i]);
    }
}

/** Initializes vue component by document id */
export function initializeVueByDocumentId(documentId){
    initializeVueElement(document.getElementById(documentId));
}

/** Initializes vue component */
export function initializeVueElement(element){
    let moduleData = JSON.parse(element.getAttribute('data-module-options')) || {};
    new Vue({ el: element, data: moduleData });
}

/** Initializes the element modules on the current document */
export function initializeModules(){
    const moduleElements = document.querySelectorAll('[data-module]');
    for (var i = 0; i < moduleElements.length; i++) {
        let result = initializeElementModule(moduleElements[i]);
        if(!result){
            break;
        }
    }
}

export function initializeContainerModules(container){
    const moduleElements = container.querySelectorAll('[data-module]');
    for (var i = 0; i < moduleElements.length; i++) {
        let result = initializeElementModule(moduleElements[i]);
        if(!result){
            break;
        }
    }
}

export function initializeMobileMenu() {

    var toggleBtn = $('[data-toggle="accordion-menu"]');  
    var accordionMenu = toggleBtn.next('[data-accordion-menu]');    

    if ($(window).width() <= 640) {
        // Initialize accordion menu for mobile
        toggleBtn.find('span').text(toggleBtn.data('show'));
        toggleBtn.show();
        accordionMenu.hide();

        $(document).on('click', '[data-toggle="accordion-menu"]', function() {

            var toggleBtn = $(this);
            var accordionMenu = toggleBtn.next('[data-accordion-menu]');

            if (accordionMenu.is(':visible')) {
                accordionMenu.hide(300);
                toggleBtn.attr('aria-expanded', false);
                toggleBtn.find('i').removeClass('icon-arrow-up').addClass('icon-arrow-down');
                toggleBtn.find('span').text(toggleBtn.data('show'));
            }
            else {
                accordionMenu.show(300);
                toggleBtn.attr('aria-expanded', true);
                toggleBtn.find('i').removeClass('icon-arrow-down').addClass('icon-arrow-up');
                toggleBtn.find('span').text(toggleBtn.data('hide'));
            }
        });
    }
}

export function initializeAccordionMenuAccessibilityFix() {

    $(document).on('click', 'button[class="submenu-toggle"]', function() {
        if ($(this).attr('aria-expanded') == 'false') { // region is collapsed
            // update the button label
            $(this).attr('title', 'Open submenu').find('.submenu-toggle-text').html('Open submenu');
        }
        else { // region is expanded
            // update the button label
            $(this).attr('title', 'Sluit submenu').find('.submenu-toggle-text').html('Sluit submenu');
        }
    });
}

/**
 * Initializes the element module
 * @param {string} documentId 
 */
export function initializeModule(documentId){
    return initializeElementModule(document.getElementById(documentId));
}

/**
 * Initializes the element module
 * @param {HTMLElement} el 
 */
function initializeElementModule(el){
    let result = true;
    const name = el.getAttribute('data-module');
    let options = el.getAttribute('data-module-options');
    let manual = el.getAttribute('data-module-manual');

    if (name === '') {
        if (DEBUG) {
            console.warn('Name ' + name + ' was not a valid module name.');
        }
        result = false;
    }

    if(result) {
        if (JSON.parse(options)) {
            // Options are succesfully parseable to json
            options = JSON.parse(options);
        }
        else if (typeof options !== 'undefined' && options !== null) {
            // Invalid options sent
            if (DEBUG) {
                console.warn('The options object supplied with ' + name + ' module seems to be invalid json.');
            }
            result = false;
        }
        else {
            // No options provided.
            options = {}; // fallback
        }
        
        if (!manual) {
            const Module = require(`./${name}`).default;
            new Module(el, options);
        } else {
            console.log('Module ' + name + ' is set to manual loading. Skipping.');
        }
    }

    return result;
}

export function initializeObjectFitImages() {
    objectFitImages();
}
