import Vue from 'vue/dist/vue.esm';

export default Vue.component('ktkeditormodalui', {
    template:`
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <slot name="header">
                        <div>Header</div>
                    </slot>
                    <div class="modal-body">
                        <slot name="content">
                            <div>Content</div>
                        </slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer">
                            <div>Footer</div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>`
});