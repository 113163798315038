// Add manual core-js polyfills, since babel doesn't recognize required ones
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.find-index';
import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import * as Setup from '../../general';

export default Vue.component('footermodule', {
    template:`
        <div v-html="footer"></div>
        <p>{{ nodeguid }}</p>
    `,
    data: function(){
        return {
            axe: axios.create({ baseURL: '/footer' }),
            footer: ''
        }
    },
    props: {
        nodeguid: String
    },
    updated: function(){
        Setup.initializeContainerModules(this.$el);
        Setup.initializeFoundationElement(this.$el);
    },
    created: function() {
        this.axe.get(`/footer?nodeguid=${this.nodeguid}`)
            .then((response) => { this.footer = response.data; })
            .catch((error) => { console.log('Error retrieving footer data : ' + error); });
    }
});   