import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import MultipleItemSelectorModal from './multipleitemselectormodal';

export default Vue.component('multipleitemselector', {
    template:`
    <div>
        <table class="unstriped">
            <thead>
                <tr>
                    <th><input type="checkbox" /></th>
                    <th>Item</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item , index) in items" v-if="items.length">
                    <td>
                        <span class="ktc-checkbox">
                            <input v-bind:name="'kentico-form.SelectedItems.SelectedItems['+index+'].Checked'" type="checkbox" data-ktc-notobserved-element="" v-bind:id="item.itemId" v-model="item.checked" />
                            <label v-bind:for="item.itemId"> </label>
                        </span>
                    </td>
                    <td>
                        <span>{{ item.label }}</span>
                        <input type="hidden" v-bind:name="'kentico-form.SelectedItems.SelectedItems['+index+'].Label'" v-bind:value="item.label" />
                        <input type="hidden" v-bind:name="'kentico-form.SelectedItems.SelectedItems['+index+'].ItemId'" v-bind:value="item.itemId" />
                        <input type="hidden" v-bind:name="'kentico-form.SelectedItems.SelectedItems['+index+'].Value'" v-bind:value="item.value" />
                    </td>
                </tr>
            </tbody>
        </table>
        <modal v-if="showModal" v-on:close="showModal = false" key="selector-modal" 
            v-on:confirm="updateSelection"
            v-bind="{ baseUrl, currentSelection, searchParameters, filters }" 
            v-bind:paging="pagination" >
        </modal>
        <div class="btn-group btn-group-sm" role="group">
            <button type="button" v-on:click="removeSelection" value="Remove items" class="ktc-btn ktc-btn-default">Remove items</button>
            <button type="button" v-on:click="showModal = true" class="ktc-btn ktc-btn-primary">Add items</button>
        </div>
    </div>
    `,
    data: function(){
        return {
            showModal: false,
            axe: axios.create({
                baseURL: this.$props.baseUrl,
                timeout: 1500,
                paramsSerializer: function(params) {
                    return qs.stringify(params, { indices: false, allowDots: true })
                }
            }),
            items: this.$props.selectedItems,
            pagination: this.$props.paging
        }
    },
    computed: {
        currentSelection: function(){
            return this.items.map(o => o.value);
        }        
    },
    components: {
        'modal': MultipleItemSelectorModal
    },
    props: {
        baseUrl: String,
        selectedItems: Array,
        searchParameters: Object,
        paging: Object,
        filters: Object
    },
    methods:{
        removeSelection: function() {
            this.items = _.filter(this.items, o => !o.checked);
        },
        updateSelection: function(itemSelection){
            this.axe.get('/getselecteditems', { params: { selectedItems: itemSelection } })
                .then((response) => {
                    this.items = response.data;
                    this.showModal = false;
                })
                .catch((error) => { 
                    console.log('Error updating selection : ' + error); 
                });
        }
    }
});