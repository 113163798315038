import $ from 'jquery';

export default class SearchBar {
    constructor(el, options) {
        let _self = this;
        this.elem = el;
        this.c = options || {};
        
        let searchBar = $(el);
        let toggleBtn = $('[data-toggle="searchbar"]');
        let textBox = searchBar.find('[name="keyword"]');
        let searchBtn = searchBar.find('button[type="submit"]');
        let labelSearch = toggleBtn.find('.show-for-sr');

        toggleBtn.on('click', (elem) => {

            if ($(this.elem).hasClass('open')) {
                $(this.elem).removeClass('open').attr('aria-expanded', false);
                toggleBtn.find('i').removeClass('icon-cross').addClass('icon-search');
                textBox.attr('tabindex', -1);
                searchBtn.attr('tabindex', -1);
                labelSearch.text('Zoeken');
            }
            else {
                $(this.elem).addClass('open').attr('aria-expanded', true);
                toggleBtn.find('i').removeClass('icon-search').addClass('icon-cross');
                textBox.focus().attr('tabindex', 0);
                searchBtn.attr('tabindex', 0);
                labelSearch.text('Zoeken afsluiten');
            }
        });
    }
}
