import $ from 'jquery';
import validate from 'jquery-validation';
import axios from 'axios';
import qs from 'qs';
import { Notyf } from 'notyf';

export default class NewsletterUnsub {
    constructor(el, options) {

        this.elem = $(el);
        this.c = options || {};
        this.axe = axios.create({
            baseURL: '/newsletter',
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false, allowDots: true });
            }
        });

        this.notyf = new Notyf({
            types: [
                {
                    type: 'info',
                    backgroundColor: '#002761',
                    icon: { className: 'material-icons', tagName: 'i', text: 'warning' }
                }
              ]
        });

        this.initializeElements();
    }

    initializeElements(){
        const _self = this;

        _self.elem.validate({
            focusInvalid: false,
            focusCleanup: true,
            rules: {
                emailaddress: { required: true, email: true }
            },
            errorPlacement: function(error, element) {}
        });

        
        _self.elem.submit(function(event) { 
            var isvalid = _self.elem.valid();
            
            if (isvalid) {
                event.preventDefault();
                _self.submitForm();
            }            
        });
    }

    submitForm(){
        let _self = this;
        var data = _self.elem.serialize();

        _self.axe.get('/unsubscribenewsletter?' + data)
            .then((response) => { 
                if(response.data.actionSuccess) {
                    if(response.data.unsubscribed) {
                        _self.notyf.success(response.data.message);
                    } else {
                        _self.notyf.open({ type: 'info', message : response.data.message });
                    }
                }
            })
            .catch((error) => { console.log('unsubscribe failed : ' + error); });
    }
}