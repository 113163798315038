// Add manual core-js polyfills, since babel doesn't recognize required ones
import 'core-js/modules/es.object.assign';
import Vue from 'vue/dist/vue.esm';
import VueMq from 'vue-mq';
import axios from 'axios';
import Paginate from './paginate'
import qs from 'qs';
import { PageMode } from './core/pagemode';

Vue.use(VueMq, {
    breakpoints: {
        small: 640,
        medium: 1024,
        large: 1200
    }
})

export default class Overviews {
    constructor(el, options) {
        let _self = this;
        this.elem = el;
        this.c = options || { };
        this.c.reloadtimer = '';
        this.axe = axios.create({
            baseURL: this.c.baseUrl,
            //timeout: 1000, 
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false, allowDots: true })
            }
        });

        this.bindVue();
    }

    bindVue() {
        let _self = this;
        var app = new Vue({
            el: _self.elem,
            components: {
                'paginate': Paginate
            },
            data: {
                filters: _self.c.filters || [],
                results: _self.c.results || [],
                resultCount: 0,
                paging: _self.c.paging || {
                    pageSize: 10,
                    pageNumber: 1
                },
                searchparams: _self.c.searchParameters,
                filterToggle: false,
                mode: _self.c.mode,
                resultCount: _self.c.resultCount,
                loading: true
            },
            methods: {
                retrieveFilters: function(){
                    if(this.mode === PageMode.Live){
                        _self.axe.get(_self.c.searchFiltersAction, { params : this.searchparams })
                            .then((response) => { 
                                this.filters = response.data;
                            })
                            .catch((error) => { 
                                console.log('Updating filters failed : ' + error);
                            });
                    }
                },
                retrieveSearchResult: function() {
                    if(this.mode === PageMode.Live){
                        
                        this.loading = true;

                        const queryParams = Object.keys(this.searchparams).reduce((object, key) => {
                            // Never add pageNumber parameter, this has a default value in the controller
                            if (key !== 'pageSize' && key !== 'pageNumber' && key !== 'keyword') {
                                object[key] = this.searchparams[key]
                            }
                            // Only add keyword parameter if it isn't empty
                            if (this.searchparams['keyword'] !== '') {
                                object['keyword'] = this.searchparams['keyword']
                            }
                            // Only add pageSize parameter if it's more than 1
                            if (this.searchparams['pageNumber'] !== 1) {
                                object['pageNumber'] = this.searchparams['pageNumber']
                            }
                            return object
                        }, {});

                        // Push query string to history
                        let queryString = qs.stringify(queryParams, { indices: false, allowDots: true });
                        if (queryString !== '') {
                            window.history.pushState(null, null, window.location.origin + window.location.pathname + '?' + queryString);
                        } else {
                            window.history.pushState(null, null, window.location.origin + window.location.pathname);
                        }

                        _self.axe.post(_self.c.searchAction, this.searchparams)
                        .then((response) => {
                            this.results = response.data.resultItems;
                            this.resultCount = response.data.resultCount;
                            this.paging.pageCount = response.data.pageCount;
                            
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.log('Updating search result failed : ' + error);
                            this.loading = false;
                        });
                    }
                },
                retrieveSearchResultsFromPaging: function(page) {
                    this.searchparams.pageNumber = page;
                    this.searchparams.pageSize = this.paging.pageSize;
                    this.setFilters();
                },
                updateKeyword: function() {
                    // Call updateFilters with a timeout so it doesn't fire too many calls
                    clearTimeout(_self.c.reloadtimer);
                    _self.c.reloadtimer = setTimeout(() => {
                        this.retrieveSearchResultsFromPaging(1);
                    }, 500);
                },
                updateFilters: function() {
                    this.retrieveSearchResultsFromPaging(1);
                },
                setFilters: function(){
                    this.filters.forEach((filter) => {
                        this.searchparams[filter.code] = [];

                        filter.items.forEach((filterItem) => {
                            if(filterItem.isActive){
                                this.searchparams[filter.code].push(filterItem.value);
                            }
                        });
                    });

                    this.retrieveSearchResult();
                }
            },
            created: function() {
                this.retrieveFilters();
                this.retrieveSearchResult();
            }
        });    
    }
}