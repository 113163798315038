import Vue from 'vue/dist/vue.esm';
import Grid from './singleitemselectorgrid';

export default Vue.component('singleitemselectormodal', {
    template:`
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="flv modal-container">
                    <div class="modal-header">
                        <div class="modal-header-controls">
                            <a class="bo-icon" @click="onCloseModal">
                                <i aria-hidden="true" title="Close" class="icon-modal-close"></i>
                            </a>
                        </div>
                        <div class="modal-header-title">Select items</div>
                    </div>
                    <div class="modal-body">
                        <grid v-bind="{ baseUrl, currentSelection, paging, modalId, searchParameters, filters }" 
                            v-on:close="onCloseModal" v-on:confirm="confirmSelection"></grid>
                    </div>
                </div>
            </div>
        </div>
    </transition>`,
    props: {
        baseUrl: String,
        currentSelection: String,
        paging: Object,
        modalId: String,
        searchParameters: Object,
        filters: Object    
    },
    components: {
        'grid': Grid
    },
    methods:{
        onCloseModal: function(){
            this.$emit('close');
        },
        confirmSelection: function(itemSelection){
            this.$emit('confirm', itemSelection);
        },
    }
});