/** Import scss modules */
import './scss/main.scss';
import 'notyf/notyf.min.css';

/** Import js modules */
import * as Setup from './scripts/general'; 

/** Import required components and modules */
// // import './scripts/core/share';
import './scripts/components/core/cookiebar';
import './scripts/components/core/footer';
// // import './scripts/components/core/newsletter';
// // import './scripts/components/core/overviews';
// // import './components/core/searchbar';


/** Enable HRM */
if (module.hot) {
    module.hot.accept();
}

/** Initialize facebook */
window.fbAsyncInit = function () {
    FB.init({
        appId: '652383499323256',
        xfbml: true,
        version: 'v2.7'
    });
};

(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

/** 
 * Attach event handler for when window is loaded 
 * to initialize foundation, custom modules and vue 
 * */
window.onload = function(){
    console.log('onload from main');
    Setup.initializeWindow();
};