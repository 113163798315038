import Vue from 'vue/dist/vue.esm';
import _ from 'lodash';
import axios from 'axios';
import qs from 'qs';
import debounce from 'debounce';
import Paginate from '../../../paginate'


export default Vue.component('multipleitemselectormodal', {
    template:`
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="flv modal-container">
                    <div class="modal-header">
                        <div class="modal-header-controls">
                            <a class="bo-icon" @click="onCloseModal">
                                <i aria-hidden="true" title="Close" class="icon-modal-close"></i>
                            </a>
                        </div>
                        <div class="modal-header-title">Select items</div>
                    </div>
                    <div class="modal-body">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group row input-group-sm">
                                        <label class="col-sm-2 col-form-label">Name</label>
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control" v-model="parameters.keyword" v-on:keyup="updateKeyword()">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>
                                                <th><input type="checkbox" /></th>
                                                <th>Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in items" v-if="items.length">
                                                <td>
                                                    <input type="checkbox" v-bind:id="'selection_' + item.itemId" v-model="item.checked" v-on:change="updateItemSelection(item)" />
                                                    <label v-bind:for="'selection_' + item.itemId"><i></i></label>
                                                </td>
                                                <td><span>{{ item.label }}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="pagination.pageCount > 1">
                                        <paginate v-model="pagination.pageNumber"
                                                :page-count="pagination.pageCount"
                                                :page-range="3"
                                                :click-handler="getSearchResultsByPage"
                                                :active-Alt="'Huidige pagina'"
                                                :prev-text="'<'"
                                                :prev-alt="'Terug'"
                                                :next-text="'>'"
                                                :next-alt="'Vooruit'"
                                                :container-class="'pagination text-center'"
                                                :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="btn-group btn-group-sm float-right" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
                                        <button type="button" class="btn btn-primary" @click="confirmSelection">Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>`,
    data: function(){
        return {
            axe: axios.create({
                baseURL: this.$props.baseUrl,
                paramsSerializer: function(params) {
                    return qs.stringify(params, { indices: false, allowDots: true })
                }
            }),
            items: [],
            itemSelection: this.$props.currentSelection,
            parameters: this.$props.searchParameters,
            pagination: this.$props.paging,
            parametersFilters: { }
        }
    },
    components: {
        'paginate': Paginate
    },
    props: {
        baseUrl: String,
        currentSelection: Array,
        paging: Object,
        searchParameters: Object,
        filters: Object
    },
    methods:{
        getSearchResultsByPage: function(pageNumber){
            this.pagination.pageNumber = pageNumber;
            this.parameters.pageNumber = pageNumber;

            this.getSearchResults(this.parameters);
        },
        getSearchResults: function(parameters){
            this.axe.get('/getsearchresults', { params: parameters })
                .then((response) => { 
                    this.setSearchResults(response.data.resultItems);
                    this.pagination.pageCount = response.data.pageCount;
                    this.pagination.resultCount = response.data.resultCount;
                })
                .catch((error) => { console.log('Error retrieving results : ' + error); });
        },
        setSearchResults: function(resultItems){
            this.items = resultItems;
            this.items.forEach(element => {
                element.checked = _.includes(this.itemSelection, element.value);
            });
        },
        updateKeyword: function(){
            // move to debounce
            clearTimeout(this.reloadtimer);
            this.reloadtimer = setTimeout(() => { this.getSearchResultsByPage(1); }, 500);
        },
        /** Update grid item selection list */
        updateItemSelection: function(item){
            if(item.checked){
                this.itemSelection.push(item.value);
            } else {
                this.itemSelection = this.itemSelection.filter(o => o !== item.value);
            }
        },
        confirmSelection: function(){
            this.$emit('confirm', this.itemSelection);
        }
    },
    created: function() {
        this.getSearchResultsByPage(1);
    }
});