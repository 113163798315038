import Vue from 'vue/dist/vue.esm';

export default Vue.component('sitetreeitem', {
    name:'tree-item',
    template:`
    <li>
        <div>
            <span @click="toggle" v-if="hasChildNodes" class="tree-icon"><i :class="isOpen ? 'bo-icon-opened' : 'bo-icon-closed' "></i></span>
            <span v-if="!hasChildNodes" class="tree-icon-spacer"></span>
            <span :class="{ 'item-selected' : isSelected }" @click="selectNode">{{ item.name }}</span>
        </div>
        <ul v-show="isOpen" v-if="hasChildNodes" class="site-tree">
            <tree-item class="item" v-for="(child, index) in item.children" :key="index" :item="child" :axe="axe" @select-item="selected"></tree-item>
        </ul>
    </li>
    `,
    props: {
        item: Object,
        axe: Function
    },
    data: function () {
        return {
            isOpen: this.$props.item.isOpen,
            loadedChildNodes: this.$props.item.loadedChildNodes,
            isSelected: false
        }
    },
    computed: {
        hasChildNodes: function () {
            return this.item.hasChildren;
        },
        isExpanded: function() {
            return this.item.isOpen;
        }
    },
    methods: {
        toggle: function () {
            if (this.hasChildNodes) {
                if(this.loadedChildNodes === false) {
                    this.axe.get('/getchildnodes', { params: { nodeID: this.item.nodeID } })
                        .then((response) => {
                            this.item.children = response.data;
                            this.isOpen = !this.isOpen;
                            this.loadedChildNodes = true;
                        })
                        .catch((error) => { console.log('error retrieving child nodes : ' + error) });
                } else {
                    this.isOpen = !this.isOpen;
                }
            }
        },
        selectNode: function(){
            this.$emit('select-item', this);
        },
        selected: function(selectedItem){
            this.$emit('select-item', selectedItem);
        }
    }
});