import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import qs from 'qs';
import { debounce } from 'debounce';

export default Vue.component('itemselector', {
    props: {
        data: Object
    },
    data: function(){
        return {
            axe: axios.create({
                baseURL: this.$props.data.baseUrl,
                paramsSerializer: function(params) {
                    return qs.stringify(params, { indices: false, allowDots: true })
                }
            }),
            gridItems: [],
            selection: this.$props.data.currentSelection,
            parameters: this.$props.data.searchParameters,
            pagination: this.$props.data.pagination,
            parametersFilters: { },
            textFilterAction: debounce(() => { this.getSearchResultsByPage(1); }, 500)
        }
    },
    methods: {
        getSearchResultsByPage: function(pageNumber){
            this.pagination.pageNumber = pageNumber;
            this.parameters.pageNumber = pageNumber;

            this.getSearchResults(this.parameters);
        },
        getSearchResults: function(parameters){
            this.axe.get('/getsearchresults', { params: parameters })
                .then((response) => { 
                    this.setSearchResults(response.data.resultItems);
                    this.pagination.pageCount = response.data.pageCount;
                    this.pagination.resultCount = response.data.resultCount;
                })
                .catch((error) => { console.log('Error retrieving results : ' + error); });
        },
        setSearchResults: function(resultItems){
            this.gridItems = resultItems;
            this.onSetSearchResults();
            // this.items.forEach(element => {
            //     element.checked = _.includes(this.itemSelection, element.value);
            // });
        }
    },
    created: function() {
        this.getSearchResultsByPage(1);
    }
});