import Vue from 'vue/dist/vue.esm';

export default Vue.component('ktkeditormodal', {
    props: {
        resources: {
            type: Object,
            required: false
        },
        closeModal: Function
    },
    methods:{

    }
});