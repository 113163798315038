import Vue from 'vue/dist/vue.esm';
import KTKEditorModal from '../../../core/ktkeditor/ktkeditormodal';
import KTKEditorModalUI from '../../../core/ktkeditor/ktkeditormodalui';
import SiteTree from '../../sitetreeview/sitetree';

export default Vue.component('pathselectormodal', {
    extends: KTKEditorModal,
    components:{
        'modal': KTKEditorModalUI,
        'sitetree': SiteTree
    },
    props: {
        baseUrl: String,
        onConfirmSelection: Function
    },
    data: function(){
        return {
            selectedItem: null
        }
    },
    methods: {
        /** sitetree node selection handler */
        selectNode: function(selectedNode){            
            if(this.selectedItem !== null && this.selectedItem.item.nodeID !== selectedNode.item.nodeID){
                this.selectedItem.isSelected = false;
            }
            this.selectedItem = selectedNode;
            this.selectedItem.isSelected = true;
        },
        /** confirms the item selection and calls the item selection handler */
        confirmSelection: function(){
            let selectedNode = this.selectedItem !== null ? this.selectedItem.item : { };
            
            this.onConfirmSelection(selectedNode);
        }
    },
    template:`
    <modal>
        <template v-slot:header>
            <div class="modal-header">
                <div class="modal-header-controls">
                    <span @click="closeModal">
                        <i aria-hidden="true" :title="resources.modalCloseButton" class="bo-icon-modal-close"></i>
                    </span>
                </div>
                <div class="modal-header-title">{{ resources.modalTitle }}</div>
            </div>
        </template>
        <template v-slot:content>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <sitetree v-bind="{ baseUrl }" @select-item="selectNode"></sitetree>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="btn-group btn-group-sm float-right" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-secondary" @click="closeModal">{{ resources.cancelButton }}</button>
                            <button type="button" class="btn btn-primary" @click="confirmSelection">{{ resources.applyButton }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal>
    `
});