import $ from 'jquery';
import validate from 'jquery-validation';
import axios from 'axios';
import qs from 'qs';
import { Notyf } from 'notyf';
import Foundation from 'foundation-sites';

export default class Newsletter {
    constructor(el, options) {
        
        this.elem = $(el);
        this.c = options || {};
        this.axe = axios.create({
            baseURL: '/newsletter',
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false, allowDots: true });
            }
        });
        this.notyf = new Notyf({
            types: [
                {
                    type: 'info',
                    backgroundColor: '#002761',
                    icon: { className: 'material-icons', tagName: 'i', text: 'warning' }
                }
              ]
        });

        this.initializeElements();
    }

    initializeElements(){
        const _self = this;

        _self.elem.validate({
            focusInvalid: false,
            focusCleanup: true,
            rules: {
                emailaddress: {
                    required: true,
                    email: true
                },
                consent: {
                    required: true
                }
            },
            messages: {
                emailaddress: this.c.messages ? this.c.messages.emailaddress : undefined,
                consent: this.c.messages ? this.c.messages.consent : undefined
            },
            errorPlacement: function(error, element) {
                error.attr('role', 'alert');
                /**  Add at last position of parent element.
                 * This is done because checkboxes are build with multiple unwrapped elements. */
                element.parent().append(error);
                Foundation.reInit('equalizer');
            }
        });

        _self.elem.submit(function(event) { 
            var isvalid = _self.elem.valid();
            
            if (isvalid) {
                event.preventDefault();
                _self.submitForm();
            }            
        });
    }

    submitForm(){
        let _self = this;
        var data = _self.elem.serialize();

        _self.axe.get('/subscribe?' + data)
            .then((response) => { 
                if(response.data.actionSuccess) {
                    if(response.data.alreadySubscribed) {
                        _self.notyf.open({ type: 'info', message : response.data.message });
                    } else {
                        _self.notyf.success(response.data.message);
                    }
                }
            })
            .catch((error) => { console.log('subscribe failed : ' + error); });
    }
}