import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import qs from 'qs';
import CKEditorModal from '../../core/ckeditor/ckeditormodal';
import CKEditorModalUI from '../../core/ckeditor/ckeditormodalui';
import MediaSelector from '../mediaselector/mediaselector';

export default Vue.component('imageselectormodal', {
    extends: CKEditorModal,
    components: {
        'modal': CKEditorModalUI,
        'mediaselector': MediaSelector
    },
    props: {
        baseUrl: String,
        onItemSelected: Function
    },
    data: function(){
        return {
            axe: axios.create({
                baseURL: this.$props.baseUrl,
                paramsSerializer: function(params) {
                    return qs.stringify(params, { indices: false, allowDots: true })
                }
            }),
            mediaSelectorData: null,
            selectedMediaFile: null
        }
    },
    methods: {
        /** handler for when an item is selected on the media selector */
        itemSelected: function(item){
            this.selectedMediaFile = item;
        },
        /** confirms the item selection and calls the ckeditor item selection handler */
        confirmSelection: function(){
            this.onItemSelected(this.selectedMediaFile);
        }
    },
    created: function(){
        this.axe.get('getmediaselectordata')
            .then((response) => { this.mediaSelectorData = response.data; })
            .catch((error) => { console.log('Error retrieving image selector data : ' +  error); });
    },
    template:`
    <modal>
        <template v-slot:header>
            <div class="modal-header">
                <div class="modal-header-controls">
                    <span @click="closeModal">
                        <i aria-hidden="true" :title="resources.closeButton" class="bo-icon-modal-close"></i>
                    </span>
                </div>
                <div class="modal-header-title">{{ resources.modalTitle }}</div>
            </div>
        </template>
        <template v-slot:content>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <mediaselector v-if="mediaSelectorData !== null" :data="mediaSelectorData" @onitemselected="itemSelected"></mediaselector>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="btn-group btn-group-sm float-right" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-secondary" @click="closeModal">{{ resources.cancelButton }}</button>
                            <button type="button" class="btn btn-primary" @click="confirmSelection">{{ resources.applyButton }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal>
    `,
});