import Vue from 'vue/dist/vue.esm';
import Paginate from '../../../paginate'
import Popper from 'vue-popperjs';  
import SingleItemSelectorGrid from '../../core/itemselector/singleitemselectorgrid';
import { MediaFileType } from './mediafiletype';

export default Vue.component('mediafileselectorgrid', {
    extends: SingleItemSelectorGrid,
    template:`
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group row input-group-sm">
                    <label class="col-sm-2 col-form-label">Name</label>
                    <div class="col-sm-4">
                        <input type="text" class="form-control" v-model="parameters.keyword" v-on:keyup="updateKeyword()">
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <table class="table table-sm singleselection">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Size</th>
                            <th>Modified</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items" v-if="items.length" v-bind:class="{ 'selected': item.value === itemSelection }">
                            <td>
                                <div class="selectarea" v-on:click="updateItemSelection(item)">
                                    <span class="bo-icon" v-on:click="previewFile($event, item)">
                                        <i aria-hidden="true" class="icon-eye"></i>
                                    </span>
                                    <popper v-if="showPreview(item)" trigger="hover" :options="{placement: 'top'}">
                                        <div class="popper">
                                            <img v-bind:src="item.previewUrl"></img>
                                        </div>
                                        <span slot="reference" class="bo-icon"><i aria-hidden="true" v-bind:class="mediaItemIcon(item)"></i></span>
                                    </popper>
                                    <span v-else class="bo-icon"><i aria-hidden="true" v-bind:class="mediaItemIcon(item)"></i></span>
                                    <span>{{ item.label }}</span>
                                </div>
                            </td>
                            <td><span>{{ item.fileExtension }}</span></td>
                            <td><span>{{ item.fileSize }}</span></td>
                            <td><span>{{ item.lastModifiedOn }}</span></td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="pagination.pageCount > 1">
                    <paginate v-model="pagination.pageNumber"
                            :page-count="pagination.pageCount"
                            :page-range="3"
                            :click-handler="getSearchResultsByPage"
                            :active-Alt="'Huidige pagina'"
                            :prev-text="'<'"
                            :prev-alt="'Terug'"
                            :next-text="'>'"
                            :next-alt="'Vooruit'"
                            :container-class="'pagination text-center'"
                            :page-class="'page-item'">
                    </paginate>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="btn-group btn-group-sm float-right" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
                    <button type="button" class="btn btn-primary" @click="confirmSelection">Apply</button>
                </div>
            </div>
        </div>
    </div>
    `,
    components: {
        'paginate': Paginate,
        'popper': Popper
    },
    methods: {
        showPreview: function(item){
            return item.type === MediaFileType.Image;
        },
        mediaItemIcon: function(item){
            return {
                'icon-picture': item.type === MediaFileType.Image,
                'icon-file-pdf': item.type === MediaFileType.Pdf,                
                'icon-file': item.type === MediaFileType.Other,
            };
        },
        previewFile: function(event, item){
            window.open(item.fileUrl);
        }
    }
});