import Vue from 'vue/dist/vue.esm';

export default Vue.component('ktkformcomponent', {
    props: {
        
    },
    methods: {
        openModal: function(){
            this.showModal = true;
        },
        closeModal: function(){
            this.showModal = false;
        }
    }
});