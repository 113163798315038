import Vue from 'vue/dist/vue.esm';
import KTKFormComponent from '../../../core/ktkeditor/ktkformcomponent';
import PathSelectorModal from './pathselectormodal';

export default Vue.component('pathselector', {
    extends: KTKFormComponent,
    components:{
        'modal': PathSelectorModal
    },
    props: {
        baseUrl: String,
        bindedPropertyName: String,
        resources: {
            type: Object,
            required: false
        }
    },
    data: function(){
        return {
            currentSelection: { },
            currentSelectionLabel: '',
            showModal: false,
            pagination: { },
            searchParameters: { },
            filters: { }
        };
    },
    methods: {
        removeSelection: function(){
            this.selectedItem = null;
        },
        onConfirmSelection: function(selectedItem){
            if(selectedItem !== null){
                this.currentSelection = selectedItem.documentGUID;
                this.currentSelectionLabel = selectedItem.documentNamePath;
            }
            this.closeModal();
        }
    },
    template: `
    <div>
        <div class="input-group input-group-sm">            
            <span class="form-control">{{ currentSelectionLabel }}</span>
            <input type="hidden" :name="bindedPropertyName" v-bind:value="currentSelection" />
            <button class="btn btn-secondary" type="button" v-on:click="removeSelection">Clear</button>
            <button class="btn btn-primary" type="button" v-on:click="openModal">Select</button>
        </div>

        <modal v-if="showModal" key="'selector-modal' + bindedPropertyName" 
            v-bind="{ baseUrl, currentSelection, searchParameters, filters, resources, closeModal, onConfirmSelection }" 
            v-bind:paging="pagination" >
        </modal>

    </div>`
});