import Vue from 'vue/dist/vue.esm';
import Popper from 'vue-popperjs';  
import Paginate from '../../../paginate'
import ItemSelector from '../../core/itemselector/itemselector';
import { MediaFileType } from '../itemselector/mediafiletype';

/**
 * Media selector grid component
 */
export default Vue.component('mediaselector', {
    extends: ItemSelector,
    methods:{
        onSetSearchResults: function(){

        },
        showPreview: function(item){
            return item.type === MediaFileType.Image;
        },
        mediaItemIcon: function(item){
            return {
                'bo-icon-picture': item.type === MediaFileType.Image,
                'bo-icon-file-pdf': item.type === MediaFileType.Pdf,                
                'bo-icon-file': item.type === MediaFileType.Other,
            };
        },
        previewFile: function(event, item){
            window.open(item.fileUrl);
        },
        /** Update grid item selection list */
        updateItemSelection: function(item){            
            if(this.selection === item.value){
                this.selection = null;
            } else {
                this.selection = item.value;
            }

            this.$emit('onitemselected', item);
        }
    },
    components: {
        'paginate': Paginate,
        'popper': Popper
    },
    template:`
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group row input-group-sm">
                    <label class="col-sm-2 col-form-label">{{ data.resources.nameFilter }}</label>
                    <div class="col-sm-4">
                        <input type="text" class="form-control" v-model="parameters.keyword" v-on:keyup="textFilterAction">
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <table class="table table-sm singleselection">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{{ data.resources.nameColumn }}</th>
                            <th>{{ data.resources.typeColumn }}</th>
                            <th>{{ data.resources.sizeColumn }}</th>
                            <th>{{ data.resources.modifiedColumn }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="gridItems.length" v-for="item in gridItems" v-bind:class="{ 'selected': item.value === selection }">
                            <td>
                                <span class="itemselector-control" v-on:click="previewFile($event, item)">
                                    <i aria-hidden="true" class="bo-icon-eye"></i>
                                </span>
                                <popper v-if="showPreview(item)" trigger="hover" :options="{placement: 'top'}">
                                    <div class="popper">
                                        <img v-bind:src="item.previewUrl"></img>
                                    </div>
                                    <span slot="reference" class="itemselector-control"><i aria-hidden="true" v-bind:class="mediaItemIcon(item)"></i></span>
                                </popper>
                                <span v-else class="itemselector-control"><i aria-hidden="true" v-bind:class="mediaItemIcon(item)"></i></span>
                            </td>
                            <td>
                                <div class="selectarea" v-on:click="updateItemSelection(item)">
                                    <span>{{ item.label }}</span>
                                </div>
                            </td>
                            <td><span>{{ item.fileExtension }}</span></td>
                            <td><span>{{ item.fileSize }}</span></td>
                            <td><span>{{ item.lastModifiedOn }}</span></td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="pagination.pageCount > 1">
                    <paginate v-model="pagination.pageNumber"
                        :page-count="pagination.pageCount"
                        :page-range="3"
                        :click-handler="getSearchResultsByPage"
                        :active-Alt="'Huidige pagina'"
                        :prev-text="'<'"
                        :prev-alt="'Terug'"
                        :next-text="'>'"
                        :next-alt="'Vooruit'"
                        :container-class="'pagination text-center'"
                        :page-class="'page-item'">
                    </paginate>
                </div>
            </div>
        </div>
    </div>
    `
});