import Vue from 'vue/dist/vue.esm';

export default Vue.component('ckeditormodalui', {
    template:`
    <div class="modal-container">
        <slot name="header">
            <div>Header</div>
        </slot>
        <div class="modal-body">
            <slot name="content">
                <div>Content</div>
            </slot>
        </div>
        <div class="modal-footer">
            <slot name="footer">
                <div>Footer</div>
            </slot>
        </div>
    </div>
    `
});