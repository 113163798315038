import $ from 'jquery';

export default class Share {
    constructor(el, options) {
        let _self = this;
        this.elem = el;
        this.c = options || {};

        var _shareType = $(this.elem).data('share-type');

        if (_shareType == "facebook") {
            $(this.elem).on('click', (elem) => {
                this.shareFacebook();
            });
        } else if (_shareType == "twitter") {
            $(this.elem).on('click', (elem) => {
                this.shareTwitter();
            });
        } else if (_shareType == "linkedin") {
            $(this.elem).on('click', (elem) => {
                this.shareLinkedIn();
            });
        } else if (_shareType == "whatsapp") {
            $(this.elem).on('click', (elem) => {
                this.shareWhatsapp();
            });
        } else if (_shareType == "mail") {
            $(this.elem).on('click', (elem) => {
                this.shareMail();
            });
        }
    }

    shareFacebook() {
        var share = {};
        share.url = $(this.elem).data('share-url');
        share.description = $(this.elem).data('share-description');

        FB.ui({
            method: 'share',
            href: share.url,
            title: share.description,
        },
        function (response) {
            // your code to manage the response
        });
    }

    shareTwitter() {
        var share = {};
        share.url = encodeURIComponent($(this.elem).data('share-url'));
        share.description = encodeURIComponent($(this.elem).data('share-description'));
        share.via = $(this.elem).data('share-via');
        share.hashtags = $(this.elem).data('share-hashtags');

        var str = 'https://twitter.com/intent/tweet?url=' + share.url + '&text=' + share.description + '&via=' + share.via + '&hashtags=' + share.hashtags;

        this.popupCenter(
            str,
            'Twitter',
            600,
            300
        )
    }

    shareLinkedIn() {
        var share = {};
        share.url = $(this.elem).data('share-url');

        var str = 'https://www.linkedin.com/shareArticle?mini=true&url=' + share.url;

        this.popupCenter(
            str,
            'LinkedIn',
            600,
            300
        );
    }

    shareMail() {
        var share = {};
        share.url = $(this.elem).data('share-url');
        share.subject = $(this.elem).data('share-subject');
        share.body = encodeURIComponent($(this.elem).data('share-body'));

        document.location.href = 'mailto:?subject=' + share.subject + '&body=' + share.body;
    }

    popupCenter(url, title, w, h) {
        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;
        var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
    }
}